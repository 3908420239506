import { gql } from 'graphql-request'
export const MetricsGet = gql`
	query UTMS($anonymUid: String!) {
		metricsGet(
			request: {
				keys: ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"]
				anonymUid: $anonymUid
			}
		) {
			content {
				key
				value
			}
		}
	}
`
